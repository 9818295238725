import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [


  {
    pid: 4,
    lpSymbol: 'CPT-EGON LP',
    lpAddresses: {
      271271: '',
      271: '0x6b2Ddf14F36e9612ce96dedB4C652f5C6F590450',
    },
    token: serializedTokens.cpt,
    quoteToken: serializedTokens.wbnb,
  },


  {
    pid: 5,
    lpSymbol: 'USDT-EGON LP',
    lpAddresses: {
      271271: '',
      271: '0xfAeEF9F9d4b97cCDe08a80d1bE86278F94d6A45D',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wbnb,
  },


  {
    pid: 6,
    lpSymbol: 'CPT-ENU LP',
    lpAddresses: {
      271271: '',
      271: '0x8733FeCdbfe0C94daC8A533B8df0b2b6558a0Fda',
    },
    token: serializedTokens.cpt,
    quoteToken: serializedTokens.enu,
  },


]

export default farms
