import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wbnb: new Token(
    MAINNET,
    '0xe30E2F0aFD56dacF8b8D4afBc594f63eE21B9441',
    18,
    'WEGON',
    'Wrapped EGON',
    'https://egonscan.com',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(MAINNET, '0xe30E2F0aFD56dacF8b8D4afBc594f63eE21B9441', 18, 'EGON', 'EGON', 'https://www.egonscan.com/'),
  cpt: new Token(
    MAINNET,
    '0x80E356A9308A3A261992DEAc6599582aa80aE6E3',
    18,
    'CPT',
    'Catapult Protocol Token',
    'https://catapultprotocol.com/',
  ),


  enu: new Token(
    MAINNET,
    '0xBA5b8FaD9f3F1122dD6308be95de8F2fafD0ef69',
    9,
    'ENU',
    'Egon ENU',
    'https://catapultprotocol.com/',
  ),

  usdt: new Token(
    MAINNET,
    '0x902D512EaE44fCe28f316980B1f5E4AFBae20548',
    18,
    'USDT-EGC-20',
    'USDT Token',
    'https://egonscan.com/',
  ),
}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0xe30E2F0aFD56dacF8b8D4afBc594f63eE21B9441',
    18,
    'WEGON',
    'Wrapped EGON',
    'https://egonscan.com',
  ),
  cpt: new Token(
    TESTNET,
    '0x80E356A9308A3A261992DEAc6599582aa80aE6E3',
    18,
    'CPT',
    'Catapult Protocol Token',
    'https://pancakeswap.finance/',
  ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
